import React from 'react';

function Home() {
  return (
    <div className='text-center'>
      <h2>Home page</h2>
    </div>);
}

export default Home;
